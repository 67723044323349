import VideoPlayer from '@pidk/common/src/components/VideoPlayer'
import { FieldType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import styled from 'styled-components'

type BlockComponent = React.FC<IVideo> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}

interface IVideo {
  url?: string
  youtube?: string
  autoPlay?: boolean
  layout: 'landscape' | 'portrait' | null
}

const Component = styled.div<any>`
  background: black;
  width: 100%;
  ${props => props.$layout === 'landscape' ? 'width: 100%; height: auto; aspect-ratio: 16/9;' : 'height: 100%; width: auto; aspect-ratio: 9/16;'}
  max-width: 80vw;
`

const Video: BlockComponent = ({
  url,
  youtube,
  autoPlay,
  layout
}: IVideo) => {

  return (
    <Component $layout={layout || 'landscape'}>
      <VideoPlayer
        source={url}
        youtube={youtube}
        controls
        autoPlay={autoPlay ? true : false}
        layout={layout || 'landscape'}
      />
    </Component>
  )
}

Video.schema = {
  name: 'Video',
  key: 'video',
  defaultFieldValues: {
    autoPlay: false,
    layout: 'landscape'
  },
  fields: [
    {
      key: 'url',
      type: FieldType.TEXT,
      label: 'Vimeo Mp4 Url',
      instructions: 'Copy paste mp4 url from vimeo'
    },
    {
      key: 'youtube',
      type: FieldType.TEXT,
      label: 'Youtube ID',
      instructions: 'Paste Youtube ID here. Leave rest empty.'
    },
    {
      key: 'autoPlay',
      type: FieldType.BOOLEAN,
      label: 'Autoplay'
    },
    {
      key: 'layout',
      type: FieldType.CHOICE,
      label: 'Layout',
      options: [
        {
          value: 'landscape',
          label: 'Landscape'
        },
        {
          value: 'portrait',
          label: 'Portrait'
        }
      ]
    }
  ]
}

Video.Styled = Component

export default Video
